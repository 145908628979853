<template>
  <!--
    Paypal documentation

    https://developer.paypal.com/api/nvp-soap/paypal-payments-standard/integration-guide/Appx-websitestandard-htmlvariables/#link-paymenttransactionvariables
  -->
  <form
    class="flex items-stretch w-full flex-col"
    :action="PAYPAL_FORM_ENV == 'dev' ? 'https://www.sandbox.paypal.com/cgi-bin/webscr' : 'https://www.paypal.com/cgi-bin/webscr'"
    method="post"
    target="_top"
    @submit="handleSubmit"
  >
    
    <input v-if="PAYPAL_FORM_ENV == 'dev'" type="hidden" name="business" value="sb-thmpk27596115@business.example.com" />
    <input v-else type="hidden" name="business" :value="paypalMerchantId" />
    <input type="hidden" name="cmd" value="_donations" />

    <!-- Amount should be in the following format: '10.00' -->
    <input type="hidden" name="amount" :value="amountFormattedForInput" />
    <input type="hidden" name="currency_code" value="EUR" />

    <!-- This enables the "Make your donation recurring" checkbox -->
    <input type="hidden" name="no_recurring" value="0" />


    <input type="hidden" name="return" :value="paypalSuccessPageUrl" />
    <!-- The buyer's browser is redirected to the return URL by using the POST method, and all payment variables are included. -->
    <input type="hidden" name="rm" value="2" />
    <input type="hidden" name="cancel_return" :value="paypalCancelPageUrl" />
    <input type="hidden" name="notify_url" :value="ipnWebhook"> 

    <input type="hidden" name="email_consent" :value="emailConsentValue" />
    <div class="mt-10">
      <label class="container-label text-base sm:text-lg text-black">
        <input type="checkbox" v-model="emailConsent" />
        <span class="checkmark"></span>
        <static-string> I consent to be contacted by Liberties via email.</static-string>
      </label>
    </div>
    <button
      id="donate-page-btn-pp"
      type="submit"
      class="donation-buttons -payment bg-orange text-white w-full"
    >
      <static-string>Pay With PayPal</static-string>
    </button>

    <img
      border="0"
      src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
      width="1"
      height="1"
    />
  </form>
</template>

<script>
export default {
  inject: ['paypalMerchantId', 'paypalSuccessPageUrl', 'paypalCancelPageUrl', 'ipnWebhook'],

  props: {
    donation: { required: true, type: Object }
  },


  data() {
    return {
      emailConsent: false,
      PAYPAL_FORM_ENV: process.env.PAYPAL_FORM_ENV || 'prod'
    };
  },

  methods: {
    handleSubmit() {

      this.saveCookie('donation_email_consent_pp', JSON.stringify({
        consent: this.emailConsent,
        donationAmount: this.donation.amount
      }));

      const form = this.$el;
      form.submit();
    },

    saveCookie(cookieName, cookieValue) {
      document.cookie = `${cookieName}=${cookieValue}; path=/; max-age=86400;`;
    }
  },

  computed: {
    amountFormattedForInput() {
      return `${this.donation.amount}.00`;
    },

    amount() {
      return this.donation.amount;
    },

    isRecurring() {
      return this.donation.interval === 'monthly';
    },

    emailConsentValue() {
      return this.emailConsent ? 'true' : 'false';
    }
  }
};
</script>

<style lang="scss" scoped>
.donation-buttons {
  min-height: 4rem;
}
</style>
